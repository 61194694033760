import { Link, graphql } from "gatsby";
import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default ({ data }) => {
  const [bilaketa, setBilaketa] = useState('');
  const [emaitzak, setEmaitzak] = useState(null);
  const [bilatzen, setBilatzen] = useState(false);
  const posts = data.allWpAdiera.nodes;

  useEffect(() => {
    setBilatzen(!!bilaketa);
    const normalizedBilaketa = bilaketa.toLowerCase().trim();
    setEmaitzak(posts.filter(post => post.title.toLowerCase().includes(normalizedBilaketa)));
  }, [bilaketa]);

  return (
    <Layout  title={'Hiztegia'}>
      <Seo title="Hiztegia" />
      <h1 className="text-4xl">
        Hiztegia
      </h1>
      <span>Egun, {posts.length} adiera dauzkagu Gizapediako hiztegian</span>
    <input className="my-4 p-2 border border-purple-400 w-full" value={bilaketa || ''}
      placeholder="Bilatu hiztegian"
      onChange={(e) => setBilaketa(e.target.value)}
    />
      {!bilatzen &&
        <ol className="my-5 w-full">
          {posts.map((post, index) => (
            <li key={`post${index}`} className="inline-block w-full md:w-1/4 pr-2"><Link to={`/hiztegia/${post.slug}`}>{post.title}</Link></li>
          ))}
        </ol>
      }
      {bilatzen &&
        <ol className="my-5 w-full">
          <h4 className="text-bold text-gray-700 text-2xl">{emaitzak.length} emaitza</h4>
          {emaitzak.map((post, index) => (
            <li key={`em${index}`} className="inline-block w-full md:w-1/4 my-3">
              <Link to={`/hiztegia/${post.slug}`} className="text-2xl">{post.title}</Link>
            </li>
          ))}
        </ol>
      }

    </Layout>
  );
};

export const query = graphql`
  query {
    allWpAdiera(
      limit: 99999
      sort: { fields: title, order: ASC }
     ) {
      nodes {
        title
        content
        slug
        date(formatString: "MMMM DD, YYYY")
      }
     }
  }`